// src/stores/auth.ts
import { defineStore } from 'pinia';
import { api } from '../lib/api';
import router from '../router/index';

interface Property {
    id: string
    name: string
    address: string
    suburb: string
    state: string
    postcode: string
}

interface User {
    id: string
    name: string
    email: string
    property_id: string
}

interface AuthState {
    token: string | null
    refresh_token: string | null,
    user: User | null
    property: Property | null
    loading: boolean
}

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => ({
        token: localStorage.getItem('token'),
        refresh_token: localStorage.getItem('refresh_token'),
        user: null,
        property: null,
        loading: false
    }),

    getters: {
        isAuthenticated: (state) => !!state.token,
        propertyInformation: (state): Property | null => state.property,
        // Add more specific getters if needed
        propertyName: (state): string | null => state.property?.name ?? null,
        propertyAddress: (state): string | null => state.property?.address ?? null,
        // You could also return a formatted address
        fullAddress: (state): string | null => {
            if (!state.property) return null
            return `${state.property.address}, ${state.property.suburb} ${state.property.state} ${state.property.postcode}`
        }
    },

    actions: {
        async login(email: string, password: string) {
            this.loading = true;
            try {
                const response = await api.post('/login', { email, password });
                this.token = response.data.access_token;
                this.refresh_token = response.data.refresh_token
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token)
                const propertyResponse = await api.get('/property')
                this.property = propertyResponse.data
            } catch (error: any) {
                throw error?.response?.data?.message || 'Login failed';
            } finally {
                this.loading = false;
            }
        },

        async register(name: string, email: string, password: string, propertyName: string) {
            this.loading = true;
            try {
                await api.post('/register', {
                    name,
                    email,
                    password,
                    property_name: propertyName
                });
            } catch (error: any) {
                throw error?.response?.data?.message || 'Registration failed';
            } finally {
                this.loading = false;
            }
        },

        logout() {
            this.token = null;
            this.user = null;
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            router.push('/login');
        }
    }
});