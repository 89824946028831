<!-- src/components/HomePage.vue -->
<script setup lang="ts">
import { ref } from 'vue'
import { useAuthStore } from '../stores/auth'
import heroBackground from '../assets/hero-background-waterfront-hotel.jpg'
import SetupWizard from './NewSetupWizard.vue'
import router from '../router';

const auth = useAuthStore()
const showRegister = ref(false)
const showSetupWizard = ref(false)

// Login form state
const loginEmail = ref('')
const loginPassword = ref('')
const loginError = ref('')

// Register form state
const registerName = ref('')
const registerEmail = ref('')
const registerPassword = ref('')
const propertyName = ref('')

const startSetup = () => {
  showSetupWizard.value = true
}

async function handleLogin() {
  try {
    loginError.value = ''
    await auth.login(loginEmail.value, loginPassword.value)
    await router.push('/dashboard')
  } catch (e: any) {
    loginError.value = e.message
  }
}

function toggleForm() {
  showRegister.value = !showRegister.value
}
</script>

<template>
  <div class="min-h-screen relative">
    <!-- Background Image -->
    <div class="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-50"
      :style="{ backgroundImage: `url(${heroBackground})` }">
    </div>

    <!-- Content -->
    <transition
      name="page"
      mode="out-in"
    >
      <div v-if="!showSetupWizard" class="relative min-h-screen flex items-center">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <!-- Marketing Message -->
            <div class="text-black">
              <h1 class="text-5xl font-bold mb-6">
                Simplify Your Motel Management
              </h1>
              <p class="text-xl mb-8">
                Streamline your booking process, manage rooms efficiently, and grow your revenue with our comprehensive
                motel management system.
              </p>
              <ul class="space-y-4 mb-8 text-lg">
                <li class="flex items-center">
                    <svg class="w-6 h-6 mr-2 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                    </svg>
                    Easy booking management
                </li>
                <li class="flex items-center">
                    <svg class="w-6 h-6 mr-2 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                    </svg>
                    Real-time availability updates
                </li>
                <li class="flex items-center">
                    <svg class="w-6 h-6 mr-2 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                    </svg>
                    Revenue tracking and reporting
                </li>
                <li class="flex items-center">
                    <svg class="w-6 h-6 mr-2 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                    </svg>
                    Real-time payment card processing
                </li>
            </ul>
              <button
                class="bg-yellow-500 text-black px-8 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-400 transition-colors transform hover:scale-105 transition-transform duration-200"
                @click="startSetup"
              >
                Get Started Now
              </button>
            </div>

            <!-- Auth Forms -->
            <div class="bg-white bg-opacity-90 rounded-lg shadow-xl p-8">
              <transition
                enter-active-class="transition-all duration-300 ease-out"
                leave-active-class="transition-all duration-300 ease-in"
                enter-from-class="opacity-0 transform translate-x-full"
                leave-to-class="opacity-0 transform -translate-x-full"
                mode="out-in"
              >
                <!-- Login Form -->
                <div v-if="!showRegister" class="space-y-6">
                  <h2 class="text-3xl font-bold text-gray-900">Welcome Back</h2>

                  <div v-if="loginError" class="bg-red-50 p-4 rounded-md">
                    <p class="text-sm text-red-700">{{ loginError }}</p>
                  </div>

                  <form @submit.prevent="handleLogin" class="space-y-4">
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Email</label>
                        <input 
                            type="email" 
                            v-model="loginEmail"
                            required
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                        />
                    </div>

                    <div>
                        <label class="block text-sm font-medium text-gray-700">Password</label>
                        <input 
                            type="password" 
                            v-model="loginPassword"
                            required
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                        />
                    </div>

                    <button 
                        type="submit"
                        class="w-full bg-yellow-500 text-black py-2 px-4 rounded-md hover:bg-yellow-400 transition-colors"
                    >
                        Sign In
                    </button>
                  </form>

                  <p class="text-center text-sm text-gray-600">
                    Don't have an account?
                    <button @click="toggleForm" class="text-yellow-500 hover:text-yellow-400 font-medium">
                      Register now
                    </button>
                  </p>
                </div>

                <!-- Register Form -->
                <div v-else class="space-y-6">
                  <div>
                    <label class="block text-sm font-medium text-gray-700">Name</label>
                    <input 
                        type="text" 
                        v-model="registerName"
                        required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                    />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Email</label>
                    <input 
                        type="email" 
                        v-model="registerEmail"
                        required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                    />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Property Name</label>
                    <input 
                        type="text" 
                        v-model="propertyName"
                        required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                    />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Password</label>
                    <input 
                        type="password" 
                        v-model="registerPassword"
                        required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                    />
                </div>

                <button 
                    type="submit"
                    class="w-full bg-yellow-500 text-black py-2 px-4 rounded-md hover:bg-yellow-400 transition-colors"
                >
                    Create Account
                </button>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <setup-wizard v-else />
    </transition>
  </div>
</template>

<style scoped>
.page-enter-active,
.page-leave-active {
  transition: all 0.5s ease;
}

.page-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.page-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

/* Add a subtle hover effect to the CTA button */
button {
  transition: all 0.2s ease;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Enhance the background image transition */
.bg-cover {
  transition: opacity 0.5s ease;
}

/* Add a subtle animation to the feature list items */
li {
  transition: transform 0.2s ease;
}

li:hover {
  transform: translateX(5px);
}
</style>
