<!-- src/components/DashboardNav.vue -->
<template>
  <nav class="bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex space-x-8">
          <!-- Existing nav links -->
          <router-link 
            v-for="item in navItems" 
            :key="item.path"
            :to="item.path"
            class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            :class="[
              $route.path === item.path
                ? 'border-indigo-500 text-gray-900'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            ]"
          >
            {{ item.name }}
          </router-link>
        </div>

        <!-- Logout Button -->
        <button
          @click="handleLogout"
          class="inline-flex items-center px-3 py-1 my-auto border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            class="h-5 w-5 mr-1" 
            viewBox="0 0 20 20" 
            fill="currentColor"
          >
            <path 
              fill-rule="evenodd" 
              d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z" 
              clip-rule="evenodd" 
            />
          </svg>
          Logout
        </button>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useAuthStore } from '../stores/auth'
import { useRouter } from 'vue-router'

const auth = useAuthStore()
const router = useRouter()

async function handleLogout() {
  auth.logout()
  router.push('/')
}

const navItems = [
    { name: 'Calendar', path: '/dashboard' },
    { name: 'Guests', path: '/guests' },
    { name: 'Rooms', path: '/rooms' },
    { name: 'Revenue', path: '/revenue' },
    { name: 'Notes', path: '/notes' },
    { name: 'Settings', path: '/settings' }
]
</script>