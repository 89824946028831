// src/lib/api.ts
import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api.ozreservations.com/api/v1',
    headers: {
        'Content-Type': 'application/json'
    }
});

// Create a flag to prevent multiple refresh requests
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

// Subscribe to token refresh
const subscribeTokenRefresh = (cb: (token: string) => void) => {
    refreshSubscribers.push(cb);
};

// Notify all subscribers about new token
const onTokenRefreshed = (token: string) => {
    refreshSubscribers.map(cb => cb(token));
    refreshSubscribers = [];
};

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // If error is not 401 or request already retried, reject
        if (error.response?.status !== 401 || originalRequest._retry) {
            return Promise.reject(error);
        }

        // If already refreshing, wait for new token
        if (isRefreshing) {
            try {
                const token = await new Promise<string>(resolve => {
                    subscribeTokenRefresh(token => {
                        resolve(token);
                    });
                });
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return axios(originalRequest);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
            const refreshToken = localStorage.getItem('refresh_token');
            if (!refreshToken) {
                throw new Error('No refresh token available');
            }

            const response = await api.post('/refresh', {
                refresh_token: refreshToken
            });

            const { access_token, refresh_token } = response.data;
            localStorage.setItem('token', access_token);
            localStorage.setItem('refresh_token', refresh_token);

            // Notify all subscribers about new token
            onTokenRefreshed(access_token);
            isRefreshing = false;

            // Retry original request
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            return axios(originalRequest);
        } catch (err) {
            isRefreshing = false;
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            window.location.href = '/login';
            return Promise.reject(err);
        }
    }
);

export { api };

// import axios from 'axios';

// const api = axios.create({
//     baseURL: 'https://api.ozreservations.com/api/v1',
//     headers: {
//         'Content-Type': 'application/json'
//     }
// });

// api.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// });

// api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         if (error.response?.status === 401) {
//             localStorage.removeItem('token');
//             window.location.href = '/login';
//         }
//         return Promise.reject(error);
//     }
// );

// export { api };