<!-- src/components/EditBookingModal.vue -->
<script setup lang="ts">
import { ref } from 'vue'
import { api } from '../lib/api'

interface Booking {
    id: string
    room_id: string
    name: string
    email: string
    phone: string
    address: string
    adults: number
    children: number
    infants: number
    check_in_date: string
    check_out_date: string
    customer_stripe_id: string | null
    payment_date: string | null
    amount: string
    payment_type: 'cash' | 'card' | 'credit' | 'pending',
    has_note: boolean
    created_at: string
    updated_at: string
}

interface Props {
    modelValue: boolean
    booking: Booking
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'bookingUpdated'])

const name = ref(props.booking.name)
const email = ref(props.booking.email)
const phone = ref(props.booking.phone)
const address = ref(props.booking.address)
const adults = ref(props.booking.adults)
const children = ref(props.booking.children)
const infants = ref(props.booking.infants)
const paymentType = ref(props.booking.payment_type)
const paymentDate = ref(props.booking.payment_date)
const amount = ref(props.booking.amount)
const loading = ref(false)
const error = ref('')

async function handleUpdate() {
    loading.value = true
    error.value = ''

    try {
        const response = await api.put(`/bookings/${props.booking.id}`, {
            name: name.value,
            email: email.value,
            phone: phone.value,
            address: address.value,
            adults: adults.value,
            children: children.value,
            infants: infants.value,
            payment_type: paymentType.value,
            payment_date: paymentDate.value,
            amount: amount.value
        })

        // Emit the updated booking data
        emit('bookingUpdated', response.data)
        emit('update:modelValue', false)
    } catch (e: any) {
        error.value = e.response?.data?.message || 'Failed to update booking'
    } finally {
        loading.value = false
    }
}

async function handleCancel() {
    if (!confirm('Are you sure you want to cancel this booking?')) {
        return
    }

    loading.value = true
    error.value = ''

    try {
        await api.delete(`/bookings/${props.booking.id}`)
        emit('bookingUpdated', { id: props.booking.id, deleted: true })
        emit('update:modelValue', false)
    } catch (e: any) {
        error.value = e.response?.data?.message || 'Failed to cancel booking'
    } finally {
        loading.value = false
    }
}
</script>

<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
        <div class="bg-white rounded-lg max-w-lg w-full p-6">
            <div class="flex justify-between items-center mb-4">
                <h3 class="text-lg font-medium text-gray-900">
                    Edit Booking
                </h3>
                <button @click="$emit('update:modelValue', false)" class="text-gray-400 hover:text-gray-500">
                    <span class="sr-only">Close</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>

            <form @submit.prevent="handleUpdate" class="space-y-4">
                <!-- ... similar form fields as BookingModal but with update functionality ... -->
                <div v-if="error" class="rounded-md bg-red-50 p-4">
                    <div class="text-sm text-red-700">{{ error }}</div>
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Guest Name</label>
                    <input type="text" v-model="name" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Email</label>
                    <input type="email" v-model="email" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Phone</label>
                    <input type="tel" v-model="phone" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Address</label>
                    <input type="text" v-model="address" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                </div>

                <div>
                    <label>Adults</label>
                    <input type="number" step="1" v-model="adults" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
                </div>

                <div>
                    <label>Children</label>
                    <input type="number" step="1" v-model="children" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
                </div>

                <div>
                    <label>Infants</label>
                    <input type="number" step="1" v-model="infants" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Payment Type</label>
                    <select v-model="paymentType"
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
                        <optopm value="pending">Pending</optopm>
                        <option value="cash">Cash</option>
                        <option value="card">Card</option>
                        <option value="credit">Credit</option>
                    </select>
                </div>

                <label class="block text-sm font-medium text-gray-700">Payment Date</label>
                    <input
                        type="date"
                        v-model="paymentDate"
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />

                <div class="mt-6 flex justify-end space-x-3">
                    <button
                        type="button"
                        @click="handleCancel"
                        class="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700"
                        :disabled="loading"
                    >
                        Cancel Booking
                    </button>
                    <button
                        type="submit"
                        :disabled="loading"
                        class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
                    >
                        Update Booking
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>