<!-- src/views/NotesView.vue -->
<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { DateTime } from 'luxon'
import { api } from '../lib/api'
import DashboardNav from '../components/DashboardNav.vue'
import NotesModal from '../components/NotesModal.vue'

interface Rate {
  id: string
  room_id: string
  start_date: string
  end_date: string
  rate: number
}

interface Room {
    id: string
    property_id: string
    type: string
    number: string
    description: string
    default_rate: number
    is_active: boolean
    rates: Rate[],
    created_at: string
    updated_at: string
}

interface Booking {
    id: string
    room_id: string
    name: string
    email: string
    phone: string
    address: string
    adults: number
    children: number
    infants: number
    check_in_date: string
    check_out_date: string
    customer_stripe_id: string | null
    payment_date: string | null
    amount: number
    payment_type: 'cash' | 'card' | 'credit' | 'pending',
    has_note: boolean
    created_at: string
    updated_at: string
}

interface Note {
  id: string
  booking_id: string
  dont_market_to_guest: boolean | null
  blacklist_guest: boolean | null
  blacklist_reason: string | null
  general_notes: string | null
}


const loading = ref(false)
const error = ref<string | null>(null)
const rooms = ref<Room[]>([])
const bookings = ref<Booking[]>([])
const weeks = ref<Date[][]>([])

const showNotesModal = ref(false)
const selectedBooking = ref<Booking | null>(null)

const currentViewDate = ref(new Date())

function showGuestName(booking: Booking | null) {
    
    if (booking === null) return '';
    let name = booking.name;
    
    const parts = name.trim().split(/\s+/); // Split the name into parts by spaces
    if (parts.length === 1) {
        return parts[0].toUpperCase(); // If only one part, return it in uppercase
    }
    
    const poppedSurname = parts.pop() || '';
    const surname = poppedSurname.toUpperCase();
    const restOfName = parts.join(' '); // Join the remaining parts
    
    return `${surname}, ${restOfName}`;
}

const bookingsByRoomAndDate = computed(() => {
  const mapping: Record<string, Booking> = {}
  bookings.value.forEach((booking) => {
    const roomId = booking.room_id
    const checkIn = DateTime.fromISO(booking.check_in_date)
    const checkOut = DateTime.fromISO(booking.check_out_date)

    let date = checkIn
    while (date < checkOut) {
      const dateKey = date.toISODate()
      const key = `${roomId}-${dateKey}`
      mapping[key] = booking
      date = date.plus({ days: 1 })
    }
  })
  return mapping
})

function bookingForCell(roomId: string, date: Date): Booking | null {
  const dateKey = DateTime.fromJSDate(date).toISODate()
  const key = `${roomId}-${dateKey}`
  return bookingsByRoomAndDate.value[key] || null
}

function handleCellClick(roomId: string, date: Date) {
  const booking = bookingForCell(roomId, date)
  if (booking) {
    openNotesModal(booking)
  }
}


function previousWeek() {
  currentViewDate.value = DateTime.fromJSDate(currentViewDate.value).minus({ weeks: 1 }).toJSDate()
  generateWeeks()
}

function nextWeek() {
  currentViewDate.value = DateTime.fromJSDate(currentViewDate.value).plus({ weeks: 1 }).toJSDate()
  generateWeeks()
}

// Generate the dates for the current week starting from Sunday
function generateWeeks() {
  const startOfWeek = DateTime.fromJSDate(currentViewDate.value).startOf('week') // Sunday
  const week = []
  for (let i = 0; i < 7; i++) {
    week.push(startOfWeek.plus({ days: i }).toJSDate())
  }
  weeks.value = [week]
}

const formattedWeekRange = computed(() => {
  if (!weeks.value[0] || weeks.value[0].length === 0) {
    return ''
  }
  const start = DateTime.fromJSDate(weeks.value[0][0]).toFormat('MMM d')
  const end = DateTime.fromJSDate(weeks.value[0][6]).toFormat('MMM d, yyyy')
  return `${start} - ${end}`
})

function openNotesModal(booking: Booking) {
  selectedBooking.value = booking
  showNotesModal.value = true
}

function closeNotesModal() {
  showNotesModal.value = false
  selectedBooking.value = null
}

async function fetchRoomsAndBookings() {
  loading.value = true
  error.value = null
  try {
    // Fetch rooms
    const roomsResponse = await api.get('/rooms/search')
    rooms.value = roomsResponse.data

    // Fetch bookings for the current week
    const startOfWeek = DateTime.fromJSDate(weeks.value[0][0]).toISODate()
    const endOfWeek = DateTime.fromJSDate(weeks.value[0][6]).toISODate()

    const bookingsResponse = await api.get('/bookings', {
      params: {
        start_date: startOfWeek,
        end_date: endOfWeek,
      },
    })
    bookings.value = bookingsResponse.data
  } catch (err) {
    console.error('Failed to fetch rooms or bookings:', err)
    error.value = 'Failed to load data.'
  } finally {
    loading.value = false
  }
}

const showNoteInfoModal = ref(false)
const selectedNote = ref<Note | null>(null)

function openNoteInfo(booking: Booking) {
  selectedBooking.value = booking
  fetchNoteDetails(booking.id)
}

function closeNoteInfo() {
  showNoteInfoModal.value = false
  selectedNote.value = null
}

async function fetchNoteDetails(bookingId: string) {
  try {
    loading.value = true
    const response = await api.get(`/bookings/${bookingId}/notes`)
    selectedNote.value = response.data
    showNoteInfoModal.value = true
  } catch (err) {
    console.error('Failed to fetch note details:', err)
    error.value = 'Failed to load note details.'
  } finally {
    loading.value = false
  }
}

function handleNoteSaved() {
    closeNotesModal();
    fetchRoomsAndBookings(); // Refresh data to update note icons
}

onMounted(() => {
    generateWeeks();
    fetchRoomsAndBookings();
});

watch(currentViewDate, () => {
    generateWeeks();
    fetchRoomsAndBookings();
});
</script>


<template>
  <div class="min-h-screen bg-blue-100">
    <DashboardNav />

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <!-- Calendar Header -->
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-semibold text-gray-900">Booking Notes</h1>
        <div class="flex space-x-4 items-center">
          <button @click="previousWeek"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
            Previous Week
          </button>
          <span class="text-lg font-medium">{{ formattedWeekRange }}</span>
          <button @click="nextWeek"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
            Next Week
          </button>
        </div>
      </div>

      <!-- Loading State -->
      <div v-if="loading" class="flex justify-center py-12">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>

      <!-- Error State -->
      <div v-else-if="error" class="bg-red-50 p-4 rounded-md">
        <p class="text-red-700">{{ error }}</p>
      </div>

      <!-- Calendar Grid -->
      <div v-else class="bg-white shadow rounded-lg overflow-hidden mt-8 grid grid-cols-8">
        <!-- Weekdays Header -->
        <div class="bg-gray-50 p-2"></div> <!-- Empty corner cell -->
        <div
          v-for="(date, index) in weeks[0]"
          :key="index"
          class="bg-gray-50 p-2"
        >
          <span class="text-xs font-medium text-gray-500">
            {{ DateTime.fromJSDate(date).toFormat('ccc d') }}
          </span>
        </div>

        <!-- Room Rows -->
        <template v-for="room in rooms" :key="room.id">
          <!-- Room number -->
          <div class="bg-white p-2 border-t">
            <span class="text-sm font-medium text-gray-900">{{ room.number }}</span>
          </div>

          <!-- Days -->
          <template v-for="date in weeks[0]" :key="date.toLocaleDateString() + room.id">
            <div
                :class="[
                'relative bg-white p-2 border-t',
                bookingForCell(room.id, date) ? 'bg-yellow-200 cursor-pointer' : 'cursor-default'
                ]"
                @click="handleCellClick(room.id, date)"
            >
                <!-- Optionally display booking info -->
                 <span class="text-sm">
                    {{ showGuestName(bookingForCell(room.id, date)) }}
                 </span>

                 <!-- Display the note icon if the booking has a note -->
                <template v-if="bookingForCell(room.id, date) && bookingForCell(room.id, date)!.has_note">
                    <button
                        @click.stop="openNoteInfo(bookingForCell(room.id, date)!)"
                        class="absolute top-1 right-1 text-gray-500"
                    >
                        <!-- Note icon -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M9 2a1 1 0 00-1 1v1H5a2 2 0 00-2 2v10a2 2 0 002 2h6.586A2 2 0 0013 17.414l3.707-3.707A2 2 0 0017 12.586V6a2 2 0 00-2-2h-4V3a1 1 0 00-1-1H9z" />
                        </svg>
                    </button>
                </template>

            </div>
            </template>

        </template>
      </div>
    </div>

    <!-- Notes Modal -->
    <NotesModal v-if="showNotesModal && selectedBooking" :booking="selectedBooking" @close="closeNotesModal" @note-saved="handleNoteSaved" />

    <!-- Note Info Modal -->
    <div
    v-if="showNoteInfoModal"
    class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4"
    >
    <div class="bg-white rounded-lg max-w-md w-full p-6 relative">
        <button @click="closeNoteInfo" class="absolute top-2 right-2 text-gray-400 hover:text-gray-500">
        <span class="sr-only">Close</span>
        <!-- Close icon -->
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M6 18L18 6M6 6l12 12" />
        </svg>
        </button>
        <h3 class="text-lg font-medium text-gray-900 mb-4">Booking Note</h3>
        <div v-if="selectedNote">
        <div class="space-y-2">
            <div v-if="selectedNote.dont_market_to_guest">
            <strong>Don't Market to Guest:</strong> Yes
            </div>
            <div v-if="selectedNote.blacklist_guest">
            <strong>Blacklist Guest:</strong> Yes
            <div v-if="selectedNote.blacklist_reason">
                <strong>Reason:</strong> {{ selectedNote.blacklist_reason }}
            </div>
            </div>
            <div v-if="selectedNote.general_notes">
            <strong>General Notes:</strong>
            <p>{{ selectedNote.general_notes }}</p>
            </div>
        </div>
        </div>
        <div v-else>
        <p>No note details available.</p>
        </div>
    </div>
    </div>

  </div>
</template>

