<!-- src/views/RevenueView.vue -->

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'
import { Chart, registerables } from 'chart.js'
import { api } from '../lib/api'
import DashboardNav from '../components/DashboardNav.vue'

Chart.register(...registerables)

const loading = ref(false)
const error = ref<string | null>(null)
const revenueData = ref([])
const currentYear = ref(new Date().getFullYear())

// Declare the ref for the canvas element
const revenueChart = ref<HTMLCanvasElement | null>(null)

async function fetchRevenue() {
  loading.value = true
  error.value = null
  try {
    const response = await api.get(`/revenue/monthly/${currentYear.value}`)
    revenueData.value = response.data
  } catch (err) {
    console.error('Failed to fetch revenue data:', err)
    error.value = 'Failed to load revenue data.'
  } finally {
    loading.value = false
    if (!error.value) {
      await nextTick()
      renderChart()
    }
  }
}

function previousYear() {
  currentYear.value -= 1
  fetchRevenue()
}

function nextYear() {
  currentYear.value += 1
  fetchRevenue()
}

function renderChart() {

    // Same as before, but include a check for empty data
  if (!revenueData.value.some((data: any) => parseFloat(data.total) > 0)) {
    error.value = `No revenue data for year ${currentYear.value}.`
    return
  }

  try {
    if (!revenueChart.value) {
      console.error('Revenue chart element not found')
      error.value = 'Failed to render revenue chart.'
      return
    }
    const ctx = revenueChart.value.getContext('2d')
    if (!ctx) {
      console.error('Failed to get canvas context')
      error.value = 'Failed to render revenue chart.'
      return
    }

    const labels = revenueData.value.map((data: any) => {
      return new Date(0, data.month - 1).toLocaleString('default', { month: 'long' })
    })

    const totals = revenueData.value.map((data: any) => parseFloat(data.total))

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Revenue',
            data: totals,
            backgroundColor: 'rgba(79, 70, 229, 0.5)',
            borderColor: 'rgba(79, 70, 229, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    })
  } catch (e) {
    console.error('Error rendering chart:', e)
    error.value = 'Failed to render revenue chart.'
  }
}

onMounted(() => {
  fetchRevenue()
})
</script>

<template>
  <div class="min-h-screen bg-blue-100">
    <DashboardNav />

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <!-- Year Navigation -->
      <div class="flex justify-between items-center mb-8">
        <h1 class="text-2xl font-semibold text-gray-900">Revenue</h1>
        <div class="flex space-x-4 items-center">
          <button @click="previousYear"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
            Previous Year
          </button>
          <span class="text-lg font-medium">{{ currentYear }}</span>
          <button @click="nextYear"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
            Next Year
          </button>
        </div>
      </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">

      <div v-if="loading" class="flex justify-center py-12">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>

      <div v-else-if="error" class="bg-red-50 p-4 rounded-md">
        <p class="text-red-700">{{ error }}</p>
      </div>

      <div v-else>
        <!-- Use ref instead of id -->
        <canvas ref="revenueChart"></canvas>
      </div>
    </div>
  </div>

  </div>
</template>


<style scoped>
/* Add any component-specific styles here */
</style>
