// src/router/index.ts
import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import HomePage from '../components/NewHomePage.vue';
import LoginView from '../components/LoginView.vue';
import RegisterView from '../components/RegisterView.vue';
import DashboardView from '../components/DashboardView.vue';
import ComingSoon from '../components/ComingSoon.vue';
import GuestsView from '../components/GuestsView.vue';
import RoomsView from '../components/RoomsView.vue';
import RevenueView from '../components/RevenueView.vue';
import NotesView from '../components/NotesView.vue';

const routes = [
    {
        path: '/',
        component: HomePage
    },
    {
        path: '/login',
        component: LoginView,
        meta: { requiresGuest: true }
    },
    {
        path: '/register',
        component: RegisterView,
        meta: { requiresGuest: true }
    },
    {
        path: '/dashboard',
        component: DashboardView,
        meta: { requiresAuth: true }
    },
    {
        path: '/guests',
        component: GuestsView,
        meta: { requiresAuth: true }
    },
    {
        path: '/rooms',
        component: RoomsView,
        meta: { requiresAuth: true }
    },
    {
        path: '/revenue',
        component: RevenueView,
        meta: { requiresAuth: true }
    },
    {
        path: '/notes',
        component: NotesView,
        meta: { requiresAuth: true }
    },
    {
        path: '/settings',
        component: ComingSoon,
        meta: { requiresAuth: true }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const auth = useAuthStore();

    // Log route objects directly without stringifying
    console.log('router beforeEach:', { to, from });

    if (to.meta.requiresAuth && !auth.isAuthenticated) {
        next('/login');
    } else if (to.meta.requiresGuest && auth.isAuthenticated) {
        next('/dashboard');
    } else {
        next();
    }
});

export default router;
