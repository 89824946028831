<!-- src/views/DashboardView.vue -->
<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { api } from '../lib/api'
import BookingModal from '../components/BookingModal.vue'
import EditBookingModal from './EditBookingModal.vue'
import DashboardNav from './DashboardNav.vue'
import { useAuthStore } from '../stores/auth'
import { DateTime } from 'luxon'

// Define the Rate interface
interface Rate {
  id: string
  room_id: string
  start_date: string
  end_date: string
  rate: number
}

interface Room {
    id: string
    property_id: string
    type: string
    number: string
    description: string
    default_rate: number
    is_active: boolean
    rates: Rate[],
    created_at: string
    updated_at: string
}

interface Booking {
    id: string
    room_id: string
    name: string
    email: string
    phone: string
    address: string
    adults: number
    children: number
    infants: number
    check_in_date: string
    check_out_date: string
    customer_stripe_id: string | null
    payment_date: string | null
    amount: string
    payment_type: 'cash' | 'card' | 'credit' | 'pending',
    has_note: boolean
    created_at: string
    updated_at: string
}

const auth = useAuthStore()
const rooms = ref<Room[]>([])
const bookings = ref<Booking[]>([])
// const currentMonth = ref(new Date())
const showBookingModal = ref(false)
const selectedDate = ref<Date | null>(null)
const selectedRoom = ref<Room | null>(null)
const loading = ref(true)
const showEditModal = ref(false)
const selectedBooking = ref<Booking | null>(null)
// Replace currentMonth with currentViewDate
const currentViewDate = ref(new Date()) // Initialize to today, but will change with navigation

// Add new state for info card
const showInfoCard = ref(false)
const infoCardBooking = ref<Booking | null>(null)
const infoCardTimeout = ref<ReturnType<typeof setTimeout> | null>(null)

function showGuestName(booking: Booking | null) {
    
    if (booking === null) return '';
    let name = booking.name;
    
    const parts = name.trim().split(/\s+/); // Split the name into parts by spaces
    if (parts.length === 1) {
        return parts[0].toUpperCase(); // If only one part, return it in uppercase
    }
    
    const poppedSurname = parts.pop() || '';
    const surname = poppedSurname.toUpperCase();
    const restOfName = parts.join(' '); // Join the remaining parts
    
    return `${surname}, ${restOfName}`;
}

// Remove old tooltip/hover functions and add these instead:
function showBookingInfo(booking: Booking) {

    infoCardBooking.value = booking
    showInfoCard.value = true
    
    // Clear any existing timeout
    if (infoCardTimeout.value) {
        clearTimeout(infoCardTimeout.value)
    }
    
    // Set new timeout
    infoCardTimeout.value = setTimeout(() => {
        hideBookingInfo()
    }, 10000) // 10 seconds
}

function hideBookingInfo() {
    showInfoCard.value = false
    infoCardBooking.value = null
    if (infoCardTimeout.value) {
        clearTimeout(infoCardTimeout.value)
    }
}

// function handleBookingClick(booking: Booking) {
//     showEditModal.value = true
//     selectedBooking.value = booking
// }


// Update weeks computed
const weeks = computed(() => {
    const startOfWeek = new Date(currentViewDate.value)
    // Adjust to start of week (Sunday)
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay())
    return generateCalendarDays(startOfWeek)
})

function generateCalendarDays(startDate: Date) {
    const days = []
    const current = new Date(startDate)
    const week = []
    
    // Generate just one week
    for (let i = 0; i < 7; i++) {
        week.push(new Date(current))
        current.setDate(current.getDate() + 1)
    }
    days.push(week)
    
    return days
}

function previousWeek() {
    currentViewDate.value = new Date(currentViewDate.value.setDate(currentViewDate.value.getDate() - 7))
    loadBookings()
}

function nextWeek() {
    currentViewDate.value = new Date(currentViewDate.value.setDate(currentViewDate.value.getDate() + 7))
    loadBookings()
}

function handleBookingUpdated() {
    // Reload bookings
    loadBookings()
    
    // Clear any info card showing old data
    hideBookingInfo()
    
    // Close the edit modal
    showEditModal.value = false
    selectedBooking.value = null
}

function getBookingForDate(room: Room, date: Date): Booking | null {
    // Convert the passed date to start of day for comparison
    const targetDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    
    return bookings.value.find(booking => {
        const checkIn = new Date(booking.check_in_date)
        const checkOut = new Date(booking.check_out_date)
        
        let isBooked = booking.room_id === room.id &&
            targetDate >= new Date(checkIn.getFullYear(), checkIn.getMonth(), checkIn.getDate()) &&
            targetDate < new Date(checkOut.getFullYear(), checkOut.getMonth(), checkOut.getDate())
            
        // console.log('Is booked:', isBooked)
        return isBooked
    }) || null
}

// function getBookingSpan(booking: Booking): number {
//     const checkIn = new Date(booking.check_in_date)
//     const checkOut = new Date(booking.check_out_date)
//     return Math.ceil((checkOut.getTime() - checkIn.getTime()) / (1000 * 60 * 60 * 24))
// }

function handleDateClick(date: Date, room: Room) {
    // console.log('Clicked date:', date);

    const existingBooking = getBookingForDate(room, date)
    if (existingBooking) {
        selectedBooking.value = existingBooking
        showEditModal.value = true
    } else {
        // Ensure the date is set to start of day in local timezone
        selectedDate.value = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0, 0, 0
        )
        selectedRoom.value = room
        showBookingModal.value = true
    }
}

// We're going on weeks, not months
// function nextMonth() {
//     currentMonth.value = new Date(currentMonth.value.setMonth(currentMonth.value.getMonth() + 1))
//     loadBookings()
// }

// function previousMonth() {
//     currentMonth.value = new Date(currentMonth.value.setMonth(currentMonth.value.getMonth() - 1))
//     loadBookings()
// }

async function loadRooms() {
    try {
        const response = await api.get('/rooms/search')
        rooms.value = response.data.map((room: any) => ({
            ...room,
            default_rate: parseFloat(room.default_rate)
        }))
        await loadBookings()
    } catch (error) {
        console.error('Error loading rooms:', error)
    } finally {
        loading.value = false
    }
}

async function loadBookings() {
    try {
        const weekStart = weeks.value[0][0]
        const weekEnd = weeks.value[0][6]
        const response = await api.get('/bookings', {
            params: {
                start_date: DateTime.fromJSDate(weekStart).toFormat('yyyy-LL-dd'),
                end_date: DateTime.fromJSDate(weekEnd).toFormat('yyyy-LL-dd')
            }
        })
        bookings.value = response.data
    } catch (error) {
        console.error('Error loading bookings:', error)
    }
}

// async function loadBookings() {
//     try {
//         const startDate = weeks.value[0][0]
//         const endDate = weeks.value[weeks.value.length - 1][6]
//         const response = await api.get('/bookings', {
//             params: {
//                 start_date: startDate.toLocaleDateString().split('T')[0],
//                 end_date: endDate.toLocaleDateString().split('T')[0]
//             }
//         })
//         bookings.value = response.data
//     } catch (error) {
//         console.error('Error loading bookings:', error)
//     }
// }

onMounted(() => {
    loadRooms()
})
</script>

<template>
    <div class="min-h-screen bg-blue-100">
        <DashboardNav />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div class="space-y-8">
                <!-- Calendar Header -->
                <div class="flex justify-between items-center">
                    <span>
                        <h1 class="text-2xl font-semibold text-gray-900">{{ auth.propertyName }}</h1>
                        <h2 class="text-2xl font-semibold text-gray-500">Booking Calendar</h2>
                    </span>
                    <div class="flex space-x-4 items-center">
                        <button @click="previousWeek"
                            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                            Previous Week
                        </button>
                        <span class="text-lg font-medium">
                            <template v-if="DateTime.fromJSDate(weeks[0][0]).month !== DateTime.fromJSDate(weeks[0][6]).month">
                                {{ DateTime.fromJSDate(weeks[0][0]).toFormat('LLLL') }} - 
                                {{ DateTime.fromJSDate(weeks[0][6]).toFormat('LLLL yyyy') }}
                            </template>
                            <template v-else>
                                {{ DateTime.fromJSDate(weeks[0][0]).toFormat('LLLL yyyy') }}
                            </template>
                        </span>
                        <button @click="nextWeek"
                            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                            Next Week
                        </button>
                    </div>
                </div>

                <!-- Loading State -->
                <div v-if="loading" class="flex justify-center py-12">
                    <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                </div>

                <!-- Calendar Grid -->
                <div v-else class="bg-white shadow rounded-lg overflow-hidden">
                    <div class="grid grid-cols-8 gap-px bg-gray-200">
                        <!-- Column headers -->
                        <div class="bg-gray-50 p-2">
                            <span class="text-xs font-medium text-gray-500">Room</span>
                        </div>
                        <div 
                            v-for="(date, index) in weeks[0]" 
                            :key="index"
                            class="bg-gray-50 p-2"
                        >
                            <span class="text-xs font-medium text-gray-500">
                                {{ DateTime.fromJSDate(date).toFormat('ccc d') }}
                            </span>
                        </div>
                        <!-- <div v-for="day,index in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" :key="day"
                            class="bg-gray-50 p-2">
                                <span class="text-xs font-medium text-gray-500">{{ day }} {{ currentViewDate.getDay()+index }}</span>
                        </div> -->

                        <!-- Room rows -->
                        <template v-for="room in rooms" :key="room.id">
                            <!-- Room number -->
                            <div :class="['bg-white p-2 border-t', {'opacity-50': !room.is_active}]">
                                <span class="text-sm font-medium text-gray-900">{{ room.number }}</span>
                            </div>

                            <!-- In the Days template -->
                            <template v-for="date in weeks[0]" :key="date.toLocaleDateString()">
                                <div 
                                    :class="[
                                        'relative bg-white p-2 border-t',
                                        room.is_active ? 'cursor-pointer hover:bg-gray-50' : 'cursor-not-allowed opacity-50'
                                    ]"
                                    @click="room.is_active ? handleDateClick(date, room) : null"
                                >
                                    <!-- TODO: Replace with name SURNAME, first name, the first one shows the day of week then the name -->
                                    <!-- <span class="text-sm">{{ date.getDate() }} <span class="text-xs text-gray-500">{{ showGuestName(getBookingForDate(room, date)) }}</span></span> -->
                                    <span class="text-xs text-gray-500 overflow-clip">{{ showGuestName(getBookingForDate(room, date)) }}</span>

                                    <!-- Booking indicator with info icon -->
                                    <template v-if="getBookingForDate(room, date)">
                                        <div class="absolute inset-0 flex flex-col">
                                            <!-- Info Icon -->
                                            <button 
                                                class="absolute top-0 right-0 p-1"
                                                @click.stop="showBookingInfo(getBookingForDate(room, date)!)"
                                            >
                                                <svg 
                                                    class="w-4 h-4 text-yellow-500 hover:text-yellow-600" 
                                                    fill="none" 
                                                    stroke="currentColor" 
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path 
                                                        stroke-linecap="round" 
                                                        stroke-linejoin="round" 
                                                        stroke-width="2" 
                                                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                            </button>
                                            
                                            <!-- Booking indicator bar -->
                                            <div 
                                                class="absolute bottom-0 left-0 right-0 h-1.5 bg-blue-500"
                                            ></div>
                                        </div>
                                    </template>

                                </div>
                            </template>

                        </template>
                    </div>
                </div>

                <!-- Booking details tooltip -->
                <!-- Replace the old tooltip with new info card -->
                <Teleport to="body">
                    <Transition
                        enter-active-class="transition ease-out duration-200"
                        enter-from-class="opacity-0 scale-95"
                        enter-to-class="opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-150"
                        leave-from-class="opacity-100 scale-100"
                        leave-to-class="opacity-0 scale-95"
                    >
                        <div 
                            v-if="showInfoCard && infoCardBooking"
                            class="fixed right-4 top-20 z-50 bg-white p-4 rounded-lg shadow-lg border border-gray-200 max-w-sm"
                        >
                            <div class="flex justify-between items-start mb-3">
                                <h4 class="font-medium">Booking Details</h4>
                                <button 
                                    @click="hideBookingInfo"
                                    class="text-gray-400 hover:text-gray-500"
                                >
                                    <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path 
                                            fill-rule="evenodd" 
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" 
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div class="space-y-2">
                                <p class="font-medium">{{ infoCardBooking.name }}</p>
                                <p class="text-sm text-gray-600">{{ infoCardBooking.phone }}</p>
                                <p class="text-sm text-gray-600">{{ infoCardBooking.email }}</p>
                                <p class="text-sm text-gray-600">Adults: {{ infoCardBooking.adults }}</p>
                                <p class="text-sm text-gray-600">Children: {{ infoCardBooking.children }}</p>
                                <p class="text-sm text-gray-600">Infants: {{ infoCardBooking.infants }}</p>
                                <p class="text-sm text-gray-600">
                                    Check-in: {{ new Date(infoCardBooking.check_in_date).toLocaleDateString() }}
                                </p>
                                <p class="text-sm text-gray-600">
                                    Check-out: {{ new Date(infoCardBooking.check_out_date).toLocaleDateString() }}
                                </p>
                                <p class="text-sm font-medium">
                                    Amount: ${{ parseFloat(infoCardBooking.amount).toFixed(2) }}
                                </p>
                                <p class="text-sm text-gray-600 capitalize">
                                    Payment: {{ infoCardBooking.payment_type }}
                                </p>
                                <p v-if="infoCardBooking.payment_date" class="text-sm text-gray-600 capitalize">
                                    Payment Date: {{ DateTime.fromISO(infoCardBooking.payment_date).toFormat('dd/LL/yyyy') }}
                                </p>
                            </div>
                        </div>
                    </Transition>
                </Teleport>
            </div>
        </div>

        <!-- Edit Booking Modal -->
    <EditBookingModal
        v-if="showEditModal && selectedBooking"
        v-model="showEditModal"
        :booking="selectedBooking!"
        @booking-updated="handleBookingUpdated"
    />

    <!-- New Booking Modal -->
    <BookingModal 
        v-if="showBookingModal" 
        v-model="showBookingModal" 
        :date="selectedDate!" 
        :room="selectedRoom!"
        @booked="loadBookings" 
    />
    </div>
</template>
