<script setup lang="ts">
// src/components/SetupWizard.vue
import { ref, computed } from 'vue'
import { useAuthStore } from '../stores/auth'
import { useRouter } from 'vue-router'
import { api } from '../lib/api'
import { DateTime } from 'luxon'

const router = useRouter()
const auth = useAuthStore()

const currentStep = ref(1)
const loading = ref(false)
const error = ref('')

// Step 1: Basic Info
const name = ref('')
const propertyName = ref('')

// Step 2: Rooms Setup
interface RoomGroup {
  id?: string
  type: string
  numbers: string[]
  description: string
  defaultRate: number
}

const roomNumbersString = computed({
  get: () => roomGroup.value.numbers.join(','),
  set: (value: string) => {
    // Update with proper typing
    const newNumbers: string[] = value
      .split(',')
      .map(num => num.trim())
      .filter(num => num !== '')
    roomGroup.value.numbers = newNumbers
  }
})

const roomGroup = ref<RoomGroup>({ type: '', numbers: [], description: '', defaultRate: 0 })

const roomTypes = [
  'Single',
  'Queen',
  'King',
  'Family',
  'Disabled',
  'Other'
]

// Step 3: Special Rates
interface SeasonRate {
  startDate: string
  endDate: string
  rate: number
}

const seasonRates = ref<SeasonRate[]>([
  { startDate: '', endDate: '', rate: 0 },
  { startDate: '', endDate: '', rate: 0 },
  { startDate: '', endDate: '', rate: 0 },
])

// Step 4: Account Setup
const email = ref('')
const password = ref('')

// Computed property for step validation
const canProceed = computed(() => {
  switch (currentStep.value) {
    case 1:
      return name.value.trim() && propertyName.value.trim()
    case 2:
      return roomGroup.value.type && roomGroup.value.numbers.length > 0 && roomGroup.value.defaultRate > 0
    case 3:
      return true // Special rates are optional
    case 4:
      return email.value.trim() && password.value.trim()
    default:
      return true
  }
})

const finalizing = ref(false)
const setupComplete = ref(false)

// Handle Submit Function
async function handleSubmit() {
    loading.value = true
    error.value = ''
    finalizing.value = true

    try {
        // Step 1: Register user
        await auth.register(name.value, email.value, password.value, propertyName.value)
        console.log('User registered successfully.')

        // Step 2: Log in the user to obtain tokens
        await auth.login(email.value, password.value)
        console.log('User logged in successfully.')

        // Step 3: Create rooms
        await api.post('/rooms/bulk', {
                    type: roomGroup.value.type.toLowerCase(),
                    numbers: roomGroup.value.numbers,
                    description: roomGroup.value.description,
                    default_rate: roomGroup.value.defaultRate
                })

        console.log('Rooms created successfully.')

        // Step 4: Get all rooms
        const roomsResponse = await api.get('/rooms/search')
        const rooms = roomsResponse.data
        console.log('Rooms fetched:', rooms)

        if (!Array.isArray(rooms) || rooms.length === 0) {
            throw new Error('No rooms found after creation.')
        }

        // Step 5: Create special rates
        const ratePromises = seasonRates.value
            .filter(rate => rate.startDate && rate.endDate && rate.rate && rate.rate > 0)
            .flatMap(rate =>
                rooms.map((room: { id: string }) => 
                    api.post(`/rooms/${room.id}/rates`, {
                        start_date: rate.startDate,
                        end_date: rate.endDate,
                        rate: rate.rate
                    })
                )
            )

        await Promise.all(ratePromises)
        console.log('Special rates created successfully.')

        // Step 6: Verify property
        const propertyResponse = await api.get('/property')
        auth.property = propertyResponse.data

        if (!auth.isAuthenticated || !auth.property) {
            throw new Error('Setup verification failed.')
        }

        setupComplete.value = true

        // Navigate to the dashboard
        await router.push('/dashboard')
    } catch (e: any) {
        console.error('Error during setup:', e)
        error.value = e.message || 'Setup failed.'
    } finally {
        loading.value = false
        finalizing.value = false
    }
}

// Navigation Functions
function nextStep() {
  if (currentStep.value < 5) {
    currentStep.value++
  }
}

function previousStep() {
  if (currentStep.value > 1) {
    currentStep.value--
  }
}
</script>


<template>
  <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
    <div class="bg-white rounded-xl shadow-xl w-full max-w-4xl relative">
      <!-- Progress Bar -->
      <div class="absolute top-0 left-0 right-0 h-2 bg-gray-200 rounded-t-xl">
        <div 
          class="h-full bg-yellow-500 rounded-tl-xl transition-all duration-300"
          :style="{ width: `${(currentStep / 5) * 100}%` }"
        ></div>
      </div>

      <div class="p-8 pt-12">
        <!-- Step Indicators -->
        <div class="flex justify-center mb-8">
          <div class="flex items-center space-x-4">
            <template v-for="step in 5" :key="step">
              <div 
                class="w-8 h-8 rounded-full flex items-center justify-center text-sm font-medium transition-colors"
                :class="[
                  currentStep >= step 
                    ? 'bg-yellow-500 text-black' 
                    : 'bg-gray-200 text-gray-500'
                ]"
              >
                {{ step }}
              </div>
              <div 
                v-if="step < 5" 
                class="w-16 h-0.5" 
                :class="currentStep > step ? 'bg-yellow-500' : 'bg-gray-200'"
              ></div>
            </template>
          </div>
        </div>

        <div v-if="error" class="mb-6 bg-red-50 p-4 rounded-md">
          <p class="text-sm text-red-700">{{ error }}</p>
        </div>

        <!-- Step Content -->
        <div class="space-y-6">
          <!-- Step 1: Basic Info -->
          <div v-if="currentStep === 1">
            <h2 class="text-2xl font-bold mb-6">Basic Information</h2>
            <div class="space-y-4">
              <div>
                <label class="block text-sm font-medium text-gray-700">Your Name</label>
                <input 
                  type="text" 
                  v-model="name"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                />
              </div>
              <div>
                <label class="block text-sm font-medium text-gray-700">Property Name</label>
                <input 
                  type="text" 
                  v-model="propertyName"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                />
              </div>
            </div>
          </div>

          <!-- Step 2: Rooms Setup -->
          <div v-if="currentStep === 2">
            <h2 class="text-2xl font-bold mb-6">Room Configuration</h2>
            <div class="space-y-8">
              
                <h3 class="font-medium mb-4">Create some rooms</h3>
                <div class="grid grid-cols-2 gap-4">
                  <div>
                    <label class="block text-sm font-medium text-gray-700">Room Type</label>
                    <select 
                      v-model="roomGroup.type"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                    >
                      <option value="">Select type</option>
                      <option v-for="type in roomTypes" :key="type" :value="type">
                        {{ type }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">Room Numbers</label>
                    <input 
                      type="text" 
                      v-model="roomNumbersString"
                      placeholder="e.g. 101,102,103"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                    />
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">Description</label>
                    <input 
                      type="text" 
                      v-model="roomGroup.description"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                    />
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">Default Rate</label>
                    <input 
                      type="number" 
                      v-model="roomGroup.defaultRate"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                    />
                  </div>
                </div>

            </div>
          </div>

          <!-- Step 3: Special Rates -->
          <div v-if="currentStep === 3">
            <h2 class="text-2xl font-bold mb-6">Special Season Rates</h2>
            <div class="space-y-4">
              <div v-for="(rate, index) in seasonRates" :key="index" class="grid grid-cols-3 gap-4">
                <div>
                  <label class="block text-sm font-medium text-gray-700">Start Date</label>
                  <input 
                    type="date" 
                    v-model="rate.startDate"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                  />
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">End Date</label>
                  <input 
                    type="date" 
                    v-model="rate.endDate"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                  />
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">Rate</label>
                  <input 
                    type="number" 
                    v-model="rate.rate"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Step 4: Account Setup -->
          <div v-if="currentStep === 4">
            <h2 class="text-2xl font-bold mb-6">Account Setup</h2>
            <div class="space-y-4">
              <div>
                <label class="block text-sm font-medium text-gray-700">Email</label>
                <input 
                  type="email" 
                  v-model="email"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                />
              </div>
              <div>
                <label class="block text-sm font-medium text-gray-700">Password</label>
                <input 
                  type="password" 
                  v-model="password"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
                />
              </div>
            </div>
          </div>

          <!-- Step 5: Review -->
        <div v-if="currentStep === 5">
        <div v-if="finalizing" class="text-center py-12">
            <div class="inline-block animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500 mb-4"></div>
            <h3 class="text-xl font-medium text-gray-900">Finalizing Your Setup</h3>
            <p class="text-gray-500 mt-2">Please wait while we prepare your dashboard...</p>
        </div>
        
        <div v-else>
            <h2 class="text-2xl font-bold mb-6">Review Your Setup</h2>
            <div class="space-y-6">
            <div>
                <h3 class="font-medium text-gray-900">Basic Information</h3>
                <p class="mt-1 text-gray-600">{{ name }} - {{ propertyName }}</p>
            </div>

            <div>
                <h3 class="font-medium text-gray-900">Rooms</h3>
                <div class="mt-2 space-y-2">
                    <template v-if="roomGroup.type && roomGroup.numbers">
                    <p class="text-gray-600">
                        {{ roomGroup.type }} rooms ({{ roomGroup.numbers.join(', ') }}) - ${{ roomGroup.defaultRate }}
                    </p>
                    </template>
                </div>
            </div>

            <div>
                <h3 class="font-medium text-gray-900">Special Rates</h3>
                <div class="mt-2 space-y-2">
                <div v-for="(rate, index) in seasonRates" :key="index">
                    <template v-if="rate.startDate && rate.endDate && rate.rate">
                    <p class="text-gray-600">
                        {{ DateTime.fromISO(rate.startDate).toFormat('dd LLL yyyy') }} to 
                        {{ DateTime.fromISO(rate.endDate).toFormat('dd LLL yyyy') }} - 
                        ${{ rate.rate }}
                    </p>
                    </template>
                </div>
                </div>
            </div>

            <div>
                <h3 class="font-medium text-gray-900">Account</h3>
                <p class="mt-1 text-gray-600">{{ email }}</p>
            </div>
            </div>
        </div>
        </div>
    </div>

    <!-- Navigation Buttons -->
    <div class="mt-8 flex justify-between">
        <button 
          v-if="currentStep > 1 && !finalizing"
          @click="previousStep"
          class="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
        >
        Previous
        </button>
        <div></div>
        <button 
          v-if="currentStep < 5"
          @click="nextStep"
          :disabled="!canProceed"
          class="px-4 py-2 bg-yellow-500 text-black rounded-md hover:bg-yellow-400 disabled:opacity-50 disabled:cursor-not-allowed"
        >
        Next
        </button>
        <button 
          v-if="currentStep === 5 && !finalizing"
          @click="handleSubmit"
          :disabled="loading"
          class="px-4 py-2 bg-yellow-500 text-black rounded-md hover:bg-yellow-400 disabled:opacity-50"
        >
          {{ loading ? 'Setting up...' : 'Complete Setup' }}
        </button>
        </div>
      </div>
    </div>
  </div>
</template>