<!-- src/components/ComingSoon.vue -->
<template>
    <div class="min-h-screen bg-gray-50 pt-16">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="text-center">
                <h2 class="text-3xl font-bold text-gray-900">Coming Soon</h2>
                <p class="mt-4 text-lg text-gray-600">This feature is currently under development.</p>
            </div>
        </div>
    </div>
</template>