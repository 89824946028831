<!-- src/views/RegisterView.vue -->
<script setup lang="ts">
import { ref } from 'vue'
import { useAuthStore } from '../stores/auth'
import router from '../router';

const auth = useAuthStore()
const name = ref('')
const email = ref('')
const password = ref('')
const propertyName = ref('')
const error = ref('')

async function handleRegister() {
    try {
        error.value = ''
        await auth.register(name.value, email.value, password.value, propertyName.value)
        await auth.login(email.value, password.value)
        await router.push('/dashboard')
    } catch (e: any) {
        error.value = e.message
    }
}
</script>

<template>
    <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Create your account
            </h2>
            <p class="mt-2 text-center text-sm text-gray-600">
                Or
                <router-link to="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
                    sign in to your existing account
                </router-link>
            </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form @submit.prevent="handleRegister" class="space-y-6">
                    <div v-if="error" class="rounded-md bg-red-50 p-4">
                        <div class="text-sm text-red-700">{{ error }}</div>
                    </div>

                    <div>
                        <label for="name" class="block text-sm font-medium text-gray-700">Your name</label>
                        <div class="mt-1">
                            <input id="name" v-model="name" type="text" required
                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                    </div>

                    <div>
                        <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
                        <div class="mt-1">
                            <input id="email" v-model="email" type="email" required
                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                    </div>

                    <div>
                        <label for="propertyName" class="block text-sm font-medium text-gray-700">Property name</label>
                        <div class="mt-1">
                            <input id="propertyName" v-model="propertyName" type="text" required
                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                    </div>

                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                        <div class="mt-1">
                            <input id="password" v-model="password" type="password" required
                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                    </div>

                    <div>
                        <button type="submit" :disabled="auth.loading"
                            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50">
                            {{ auth.loading ? 'Creating account...' : 'Create account' }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>