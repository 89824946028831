<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { api } from '../lib/api'

interface Booking {
    id: string
    room_id: string
    name: string
    email: string
    phone: string
    address: string
    adults: number
    children: number
    infants: number
    check_in_date: string
    check_out_date: string
    customer_stripe_id: string | null
    payment_date: string | null
    amount: number
    payment_type: 'cash' | 'card' | 'credit' | 'pending',
    has_note: boolean
    created_at: string
    updated_at: string
}

interface Props {
  booking: Booking | null
}


const props = defineProps<Props>()
const emit = defineEmits(['close', 'note-saved'])

const dontMarketToGuest = ref(false)
const blacklistGuest = ref(false)
const blacklistReason = ref('')
const generalNotes = ref('')
const loading = ref(false)
const error = ref<string | null>(null)

async function fetchNotes() {
  try {
    const response = await api.get(`/bookings/${props.booking?.id}/notes`)
    const note = response.data
    if (note) {
      dontMarketToGuest.value = note.dont_market_to_guest
      blacklistGuest.value = note.blacklist_guest
      blacklistReason.value = note.blacklist_reason
      generalNotes.value = note.general_notes
    }
  } catch (e) {
    console.error('Failed to fetch notes:', e)
  }
}

async function handleSubmit() {
  loading.value = true
  error.value = null
  try {
    const payload = {
      dont_market_to_guest: dontMarketToGuest.value,
      blacklist_guest: blacklistGuest.value,
      blacklist_reason: blacklistReason.value,
      general_notes: generalNotes.value,
    }
    await api.post(`/bookings/${props.booking?.id}/notes`, payload)
    emit('note-saved')
    emit('close')
  } catch (e: any) {
    console.error('Failed to save notes:', e)
    error.value = e.response?.data?.message || 'Failed to save notes.'
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchNotes()
})
</script>

<template>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
    <div class="bg-white rounded-lg max-w-md w-full p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-medium text-gray-900">Booking Notes</h3>
        <button @click="$emit('close')" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Close</span>
          <!-- Close icon -->
        </button>
      </div>

      <form @submit.prevent="handleSubmit" class="space-y-4">
        <div v-if="error" class="rounded-md bg-red-50 p-4">
          <div class="text-sm text-red-700">{{ error }}</div>
        </div>

        <div>
          <label class="flex items-center">
            <input type="checkbox" v-model="dontMarketToGuest" class="mr-2">
            Don't Market to Guest
          </label>
        </div>

        <div>
          <label class="flex items-center">
            <input type="checkbox" v-model="blacklistGuest" class="mr-2">
            Blacklist Guest
          </label>
        </div>

        <div v-if="blacklistGuest">
          <label class="block text-sm font-medium text-gray-700">Reason for Blacklisting</label>
          <input
            type="text"
            v-model="blacklistReason"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">General Notes</label>
          <textarea
            v-model="generalNotes"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          ></textarea>
        </div>

        <div class="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            @click="$emit('close')"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            :disabled="loading"
            class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            {{ loading ? 'Saving...' : 'Save Notes' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

