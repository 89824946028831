<!-- src/components/EditRateModal.vue -->
<script setup lang="ts">
import { ref } from 'vue'
import { api } from '../lib/api'

interface Rate {
    id: string
    room_id: string
    start_date: string
    end_date: string
    rate: string
}

interface Props {
    modelValue: boolean
    rate: Rate
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'rateUpdated'])

const startDate = ref(props.rate.start_date)
const endDate = ref(props.rate.end_date)
const rateAmount = ref(props.rate.rate)
const loading = ref(false)
const error = ref('')

async function handleUpdateRate() {
    loading.value = true
    error.value = ''

    try {
        const response = await api.patch(`/rates/${props.rate.id}`, {
            start_date: startDate.value,
            end_date: endDate.value,
            rate: rateAmount.value
        })

        emit('rateUpdated', response.data)
        emit('update:modelValue', false)
    } catch (e: any) {
        error.value = e.response?.data?.message || 'Failed to update rate'
    } finally {
        loading.value = false
    }
}
</script>


<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
        <div class="bg-white rounded-lg max-w-lg w-full p-6">
            <div class="flex justify-between items-center mb-4">
                <h3 class="text-lg font-medium text-gray-900">
                    Edit Rate
                </h3>
                <button @click="$emit('update:modelValue', false)" class="text-gray-400 hover:text-gray-500">
                    <span class="sr-only">Close</span>
                    <!-- Close icon -->
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                              d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>

            <form @submit.prevent="handleUpdateRate" class="space-y-4">
                <div v-if="error" class="rounded-md bg-red-50 p-4">
                    <div class="text-sm text-red-700">{{ error }}</div>
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Start Date</label>
                    <input type="date" v-model="startDate" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">End Date</label>
                    <input type="date" v-model="endDate" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700">Rate</label>
                    <input type="number" step="1" v-model="rateAmount" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                </div>

                <div class="mt-6 flex justify-end space-x-3">
                    <button
                        type="button"
                        @click="$emit('update:modelValue', false)"
                        class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300"
                        :disabled="loading"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        :disabled="loading"
                        class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
                    >
                        Update Rate
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

