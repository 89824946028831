<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { api } from '../lib/api'
import DashboardNav from '../components/DashboardNav.vue'
// import EditRoomModal from '../components/EditRoomModal.vue'
import EditRateModal from './EditRateModal.vue'
import AddRateModal from '../components/AddRateModal.vue'
import AddRoomModal from '../components/AddRoomModal.vue'
import BulkAddRoomModal from '../components/BulkAddRoomModal.vue'
import {DateTime } from 'luxon'

// Define the Rate interface
interface Rate {
  id: string
  room_id: string
  start_date: string
  end_date: string
  rate: string
}

interface Room {
  id: string
  type: string
  number: string
  description: string
  default_rate: string
  images: string[]
  rates: Rate[]
  is_active: boolean
}

const rooms = ref<Room[]>([])
const loading = ref(false)
const error = ref<string | null>(null)
const expandedRoom = ref<string | null>(null)
const showEditModal = ref(false)
const roomToEdit = ref<Room | null>(null)
const showAddRateModal = ref(false)
const roomForRate = ref<Room | null>(null)

const showAddRoomModal = ref(false)
const showBulkAddRoomModal = ref(false)

function openAddRoomModal() {
  showAddRoomModal.value = true
}

function openBulkAddRoomModal() {
  showBulkAddRoomModal.value = true
}

function handleRoomAdded(newRoom: Room) {
  rooms.value.push(newRoom)
}

function handleRoomsAdded(newRooms: Room[]) {
  rooms.value.push(...newRooms)
}

function toggleDetails(roomId: string) {
  expandedRoom.value = expandedRoom.value === roomId ? null : roomId
}

async function fetchRooms() {
  loading.value = true
  try {
    const response = await api.get('/rooms/search')
    rooms.value = response.data
  } catch (err) {
    console.error('Failed to fetch rooms:', err)
    error.value = 'Failed to load rooms.'
  } finally {
    loading.value = false
  }
}

function editRoom(room: Room) {
  roomToEdit.value = room
  showEditModal.value = true
}

// function handleRoomUpdated(updatedRoom: Room) {
//   // Update the room in the list
//   const index = rooms.value.findIndex(r => r.id === updatedRoom.id)
//   if (index !== -1) {
//     rooms.value[index] = { ...rooms.value[index], ...updatedRoom }
//   }
// }

function addRate(room: Room) {
  roomForRate.value = room
  showAddRateModal.value = true
}

async function toggleRoomStatus(room: Room) {
  const action = room.is_active ? 'deactivate' : 'reactivate';
  const confirmed = confirm(`Are you sure you want to ${action} Room ${room.number}?`);
  if (!confirmed) return;

  loading.value = true;
  try {
    // Toggle the is_active status
    await api.patch(`/rooms/${room.id}/status`, { is_active: !room.is_active });
    // Update the room's status in the list
    const index = rooms.value.findIndex(r => r.id === room.id);
    if (index !== -1) {
      rooms.value[index].is_active = !room.is_active;
    }
  } catch (err) {
    console.error(`Failed to ${action} room:`, err);
    error.value = `Failed to ${action} room.`;
  } finally {
    loading.value = false;
  }
}

const showEditRateModal = ref(false)
const rateToEdit = ref<Rate|null>(null)

function editRate(rate: Rate) {
    rateToEdit.value = rate
    showEditRateModal.value = true
}

function handleRateUpdated(updatedRate: Rate) {
    // Find the room that contains this rate
    const room = rooms.value.find(r => r.id === updatedRate.room_id)
    if (room) {
        // Update the rate in the room's rates array
        const index = room.rates.findIndex(r => r.id === updatedRate.id)
        if (index !== -1) {
            room.rates[index] = updatedRate
        }
    }
}

onMounted(() => {
  fetchRooms()
})
</script>

<template>
  <div class="min-h-screen bg-blue-100">
    <DashboardNav />

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div class="flex justify-between items-center mb-8">
        <h1 class="text-2xl font-semibold text-gray-900">Rooms</h1>
        <div class="flex space-x-2">
          <button @click="openAddRoomModal" class="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">
            Add Room
          </button>
          <button @click="openBulkAddRoomModal" class="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700">
            Bulk Add Rooms
          </button>
        </div>
      </div>

      <div v-if="loading" class="flex justify-center py-12">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>

      <div v-else-if="error" class="bg-red-50 p-4 rounded-md">
        <p class="text-red-700">{{ error }}</p>
      </div>

      <div v-else>
        <div v-if="rooms.length === 0" class="text-center py-12 bg-white shadow rounded-lg">
          <p class="text-gray-500">No rooms found.</p>
        </div>
      </div>

      <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div v-for="room in rooms" :key="room.id" :class="room.is_active ? 'bg-white shadow rounded-lg overflow-hidden' : 'bg-gray-300 z-10 shadow rounded-lg overflow-hidden'">
          <div class="p-4">
            <h2 class="text-xl font-semibold text-gray-800">Room {{ room.number }}</h2>
            <h3 class="text-lg font-semibold text-gray-800">{{ room.type }} room</h3>
            <p class="text-gray-600 mt-2">Default Rate: ${{ parseFloat(room.default_rate).toFixed(2) }}</p>

            <div class="mt-4">
              <button @click="toggleDetails(room.id)" class="text-indigo-600 hover:text-indigo-800">
                {{ expandedRoom === room.id ? 'Hide Details' : 'Show Details' }}
              </button>
            </div>

            <div v-if="expandedRoom === room.id" class="mt-4">
              <p class="text-gray-700">{{ room.description }}</p>

               <!-- Display Special Rates -->
                <div v-if="room.rates && room.rates.length > 0" class="mt-4">
                    <h3 class="font-semibold text-gray-800">Special Rates:</h3>
                    <ul class="mt-2">
                        <li v-for="rate in room.rates" :key="rate.id">
                            {{ DateTime.fromISO(rate.start_date).toFormat('dd-LL-yyyy') }} to {{ DateTime.fromISO(rate.end_date).toFormat('dd-LL-yyyy') }}: ${{ parseFloat(rate.rate).toFixed(2) }}
                            <button @click="editRate(rate)" class="text-indigo-600 hover:text-indigo-800 ml-2">Edit</button>
                        </li>
                    </ul>
                </div>

              <div v-if="room.images && room.images.length > 0" class="mt-4">
                <h3 class="font-semibold text-gray-800">Images:</h3>
                <div class="mt-2 grid grid-cols-2 gap-2">
                  <img v-for="(image, index) in room.images" :key="index" :src="image" 
                       class="w-full h-32 object-cover rounded-md" />
                </div>
              </div>
            </div>

            <div class="mt-6 flex space-x-2">
              <button @click="editRoom(room)" 
                      class="px-3 py-1 text-sm text-indigo-600 hover:bg-indigo-50 rounded-md">
                Edit
              </button>
              <button @click="addRate(room)" 
                      class="px-3 py-1 text-sm text-green-600 hover:bg-green-50 rounded-md">
                Add Rate
              </button>
              <button @click="toggleRoomStatus(room)" 
                    :class="room.is_active ? 'text-red-600 hover:bg-red-50' : 'text-green-600 hover:bg-green-50'"
                    class="px-3 py-1 text-sm rounded-md">
                {{ room.is_active ? 'Deactivate' : 'Reactivate' }}
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <AddRoomModal
      v-if="showAddRoomModal"
      :model-value="showAddRoomModal"
      @update:modelValue="showAddRoomModal = $event"
      @roomAdded="handleRoomAdded"
    />

    <BulkAddRoomModal
      v-if="showBulkAddRoomModal"
      :model-value="showBulkAddRoomModal"
      @update:modelValue="showBulkAddRoomModal = $event"
      @roomsAdded="handleRoomsAdded"
    />

    <!-- Edit Rate Modal -->
    <EditRateModal
        v-if="showEditRateModal && rateToEdit"
        :model-value="showEditRateModal"
        @update:modelValue="showEditRateModal = $event"
        :rate="rateToEdit"
        @rateUpdated="handleRateUpdated"
    />

    <!-- Add Rate Modal -->
    <AddRateModal
      v-if="showAddRateModal"
      :model-value="showAddRateModal"
      @update:modelValue="showAddRateModal = $event"
      :room="roomForRate!"
    />
    
  </div>
</template>


<style scoped>
/* Add any component-specific styles here */
</style>
