<!-- src/components/BookingModal.vue -->
<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { api } from '../lib/api'
import { DateTime } from 'luxon'
import { useAuthStore } from '../stores/auth'
// At the top of your BookingModal.vue script
import { loadStripe, Stripe, StripeElements, StripeCardElement } from '@stripe/stripe-js'
import type { PaymentIntent } from '@stripe/stripe-js'

// Create stripe instance with publishable key
const publishableKey = import.meta.env.VITE_STRIPE_TEST_KEY
console.log('Stripe Key:', publishableKey) // Check if key is loaded

// Only initialize if we have the key
if (!publishableKey) {
    throw new Error('Stripe publishable key not found')
}

const stripePromise = loadStripe(publishableKey)
const stripe = ref<Stripe | null>(null)
const elements = ref<StripeElements | null>(null)
const cardElement = ref<StripeCardElement | null>(null)

const auth = useAuthStore()

onMounted(async () => {
    try {
        stripe.value = await stripePromise
    } catch (e) {
        console.error('Failed to load Stripe:', e)
    }
})

interface RateDetail {
  date: string
  rate: number
  is_special: boolean
}

// Define the Rate interface
interface Rate {
  id: string
  room_id: string
  start_date: string
  end_date: string
  rate: number
}

interface Room {
  id: string
  type: string
  number: string
  description: string
  default_rate: number
  images?: string[]
  rates: Rate[]
  is_active: boolean
}

interface Props {
    modelValue: boolean
    date: Date
    room: Room
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'booked'])

interface Guest {
    name: string
    email: string
    phone: string
    address: string
}

// Add these refs
const searchResults = ref<Guest[]>([])
const showSearchResults = ref(false)
const searchTimeout = ref<number | null>(null)

const name = ref('')
const email = ref('')
const phone = ref('')
const address = ref('')
const nights = ref(1)
const adults = ref(1)
const children = ref(0)
const infants = ref(0)
const paymentType = ref<'cash' | 'card' | 'credit' | 'pending'>('cash')
const paymentDate = ref(DateTime.now().toFormat('yyyy-MM-dd'))
const loading = ref(false)
const error = ref('')
const calculatedAmount = ref(0)
const ratesDetails = ref<RateDetail[]>([])

const checkInDate = computed(() => {
    return DateTime.fromJSDate(props.date).toFormat('yyyy-LL-dd')
})

const checkOutDate = computed(() => {
    return DateTime.fromJSDate(props.date)
        .plus({ days: nights.value })
        .toFormat('yyyy-LL-dd')
})

// async function calculateAmount() {
//     if (!checkInDate.value || !checkOutDate.value) return
//     try {
//         const response = await api.post('/bookings/calculate', {
//             room_id: props.room.id,
//             check_in_date: checkInDate.value,
//             check_out_date: checkOutDate.value,
//         })
//         calculatedAmount.value = response.data.total
//         ratesDetails.value = response.data.rates
//     } catch (error: any) {
//         console.error('Failed to calculate amount:', error)
//         error.value = 'Failed to calculate amount'
//     }
// }

async function calculateAmount() {
  if (!checkInDate.value || !checkOutDate.value) return
  try {
    const response = await api.post('/bookings/calculate', {
      room_id: props.room.id,
      check_in_date: checkInDate.value,
      check_out_date: checkOutDate.value,
    })
    console.log('Calculate Amount Response:', response.data)
    calculatedAmount.value = response.data.total
    ratesDetails.value = response.data.rates.map((rateDetail: RateDetail) => ({
      ...rateDetail,
      rate: Number(rateDetail.rate)
    }))
  } catch (e: any) {
    console.error('Failed to calculate amount:', e)
    error.value = 'Failed to calculate amount'
  }
}


// Watch for changes in nights or dates
watch(
  [() => nights.value, checkInDate, checkOutDate],
  () => {
    calculateAmount()
  },
  { immediate: true }
)

const amount = computed(() => calculatedAmount.value)

const stripePaymentError = ref<string>('')
const processingPayment = ref(false)

async function handleSubmit() {
    loading.value = true
    error.value = ''
    stripePaymentError.value = ''
    
    try {
        if (paymentType.value === 'card') {
            processingPayment.value = true
            
            try {
                // 1. Create booking first - add more detailed error logging
                const bookingResponse = await api.post('/bookings', {
                    room_id: props.room.id,
                    name: name.value,
                    email: email.value,
                    phone: phone.value,
                    address: address.value,
                    adults: adults.value,
                    children: children.value,
                    infants: infants.value,
                    check_in_date: checkInDate.value,
                    check_out_date: checkOutDate.value,
                    amount: amount.value,
                    payment_type: 'pending',
                    payment_date: paymentDate.value || null
                })
                
                console.log('Booking created successfully:', bookingResponse.data)
                
                // 2. Process Stripe payment with better error handling
                try {
                    if (!stripe.value) {
                        throw new Error('Stripe not initialized')
                    }

                    if (!cardElement.value) {
                        throw new Error('Card element not mounted')
                    }

                    const payment = await processStripePayment(
                        bookingResponse.data.id,
                        email.value
                    )
                    console.log('Payment processed successfully:', payment)
                    
                    // Payment successful
                    emit('booked')
                    emit('update:modelValue', false)
                } catch (stripeErr) {
                    console.error('Stripe payment error details:', stripeErr)
                    
                    if (stripeErr instanceof Error) {
                        stripePaymentError.value = stripeErr.message
                        error.value = `Payment failed: ${stripeErr.message}`
                    } else {
                        error.value = 'Payment processing failed'
                    }
                    
                    // You might want to delete/cancel the pending booking here
                    console.error('Full Stripe error:', stripeErr)

                    // Stripe error: cancel the booking
                    await api.delete('/booking/' + bookingResponse.data.id);
                }
            } catch (bookingErr: any) {
                console.error('Booking creation error:', bookingErr)
                console.error('Full booking error details:', bookingErr.response?.data)
                
                if (bookingErr.response?.data?.message) {
                    error.value = bookingErr.response.data.message
                } else if (bookingErr.message) {
                    error.value = bookingErr.message
                } else {
                    error.value = 'Failed to create booking'
                }
            }
        } else {
            // Handle non-card payments with better error handling
            try {
                const response = await api.post('/bookings', {
                    room_id: props.room.id,
                    name: name.value,
                    email: email.value,
                    phone: phone.value,
                    address: address.value,
                    adults: adults.value,
                    children: children.value,
                    infants: infants.value,
                    check_in_date: DateTime.fromISO(checkInDate.value).toFormat('yyyy-LL-dd'),
                    check_out_date: DateTime.fromISO(checkOutDate.value).toFormat('yyyy-LL-dd'),
                    amount: amount.value,
                    payment_type: paymentType.value,
                    payment_date: paymentDate.value || null
                })
                
                console.log('Non-card booking created successfully:', response.data)
                emit('booked')
                emit('update:modelValue', false)
            } catch (bookingErr: any) {
                console.error('Non-card booking error:', bookingErr)
                console.error('Full error details:', bookingErr.response?.data)
                
                if (bookingErr.response?.data?.message) {
                    error.value = bookingErr.response.data.message
                } else if (bookingErr.message) {
                    error.value = bookingErr.message
                } else {
                    error.value = 'Failed to create booking'
                }
            }
        }
    } catch (e: any) {
        console.error('Top level error:', e)
        if (e.response?.data?.message) {
            error.value = e.response.data.message
        } else if (stripePaymentError.value) {
            error.value = stripePaymentError.value
        } else if (e.message) {
            error.value = e.message
        } else {
            error.value = 'An unexpected error occurred'
        }
    } finally {
        loading.value = false
        processingPayment.value = false
    }
}

// Also improve processStripePayment error handling
async function processStripePayment(bookingId: string, email: string): Promise<PaymentIntent> {
    try {
        if (!stripe.value || !cardElement.value) {
            throw new Error('Stripe not initialized')
        }

        console.log('Creating payment intent for booking:', bookingId)

        // 1. Create payment intent
        const intentResponse = await api.post('/payments/create-payment-intent', {
            amount: amount.value,
            booking_id: bookingId,
            description: `Booking at ${auth.propertyName} ${nights.value} nights`,
            email
        })

        console.log('Payment intent response:', intentResponse.data)

        const { clientSecret } = intentResponse.data
        if (!clientSecret) {
            throw new Error('No client secret received')
        }

        console.log('Confirming card payment...')
        // 2. Confirm card payment
        const result = await stripe.value.confirmCardPayment(
            clientSecret,
            {
                payment_method: {
                    card: cardElement.value,
                    billing_details: {
                        name: name.value,
                        email: email,
                    },
                },
            }
        )

        console.log('Card payment confirmation result:', result)

        if (result.error) {
            console.error('Stripe confirmation error:', result.error)
            throw new Error(result.error.message || 'Payment failed')
        }

        if (!result.paymentIntent) {
            console.error('No payment intent returned')
            throw new Error('No payment intent returned')
        }

        return result.paymentIntent
    } catch (err) {
        console.error('Process payment error:', err)
        throw err // Re-throw to be handled by caller
    }
}

// Add debounced search function
async function searchGuests(searchTerm: string) {
    if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
    }

    if (searchTerm.length < 3) {
        showSearchResults.value = false
        return
    }

    searchTimeout.value = setTimeout(async () => {
        try {
            const response = await api.get('/guests/search', {
                params: { query: searchTerm }
            })
            searchResults.value = response.data
            showSearchResults.value = true
        } catch (error) {
            console.error('Failed to search guests:', error)
        }
    }, 300)
}

function selectGuest(guest: Guest) {
    name.value = guest.name
    email.value = guest.email
    phone.value = guest.phone
    address.value = guest.address
    showSearchResults.value = false
}

// Update name watcher
watch(() => name.value, (newValue) => {
    if (newValue) {
        searchGuests(newValue)
    }
})

function close() {
    emit('update:modelValue', false)
}

// Show payment date if not pending or card
const showPaymentDate = computed(() => {
    return !['pending', 'card'].includes(paymentType.value)
})

// Track mount state
const cardMounted = ref(false)
const cardElementRef = ref<HTMLDivElement | null>(null)

// Watch for both payment type and the ref being available
watch(
  [() => paymentType.value, cardElementRef],
  async ([newPaymentType, element]) => {
    if (newPaymentType === 'card' && element && !cardMounted.value) {
      try {
        if (!stripe.value) {
          stripe.value = await stripePromise
        }
        if (stripe.value) {
          elements.value = stripe.value.elements()
          if (elements.value) {
            cardElement.value = elements.value.create('card')
            cardElement.value.mount(element)
            cardMounted.value = true
          }
        }
      } catch (e) {
        console.error('Failed to mount Stripe:', e)
      }
    }
  },
  { immediate: true }
)

// Call calculateAmount on component mount
onMounted(() => {
    calculateAmount()
    // Existing onMounted code...
})
</script>

<template>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
    <div
      class="bg-white rounded-lg w-full max-w-4xl p-6 overflow-y-auto max-h-screen"
      style="max-height: 90vh;"
    >
      <!-- Modal Header -->
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-medium text-gray-900">
          New Booking - Room {{ room.number }}
        </h3>
        <button @click="close" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Close</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Form -->
      <form @submit.prevent="handleSubmit" class="space-y-6">
        <!-- Error Message -->
        <div v-if="error" class="rounded-md bg-red-50 p-4">
          <div class="text-sm text-red-700">{{ error }}</div>
        </div>

        <!-- Dates and Nights -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label class="block text-sm font-medium text-gray-700">Dates</label>
            <div class="mt-1 flex items-center space-x-2">
              <span class="text-sm text-gray-500">
                Check-in:
                {{ DateTime.fromISO(checkInDate).toFormat('dd/LL/yyyy') }} -
                {{ DateTime.fromISO(checkOutDate).toFormat('dd/LL/yyyy') }}
              </span>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Nights</label>
            <input
              type="number"
              v-model="nights"
              min="1"
              class="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>

        <!-- Guest Information -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Left Column -->
          <div class="space-y-4">
            <!-- Guest Name -->
            <div class="relative">
              <label class="block text-sm font-medium text-gray-700">
                Guest Name
              </label>
              <input
                type="text"
                v-model="name"
                required
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />

              <!-- Search Results Dropdown -->
              <div
                v-if="showSearchResults && searchResults.length > 0"
                class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                <div
                  v-for="guest in searchResults"
                  :key="`${guest.name}-${guest.phone}`"
                  class="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-50"
                  @click="selectGuest(guest)"
                >
                  <div class="flex items-center">
                    <span class="block truncate">
                      {{ guest.name }} - {{ guest.phone }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Email -->
            <div>
              <label class="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                v-model="email"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <!-- Phone -->
            <div>
              <label class="block text-sm font-medium text-gray-700">Phone</label>
              <input
                type="tel"
                v-model="phone"
                required
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <!-- Address -->
            <div>
              <label class="block text-sm font-medium text-gray-700">Address</label>
              <input
                type="text"
                v-model="address"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
          </div>

          <!-- Right Column -->
          <div class="space-y-4">
            <!-- Adults -->
            <div>
              <label class="block text-sm font-medium text-gray-700">Adults</label>
              <input
                type="number"
                step="1"
                v-model="adults"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <!-- Children -->
            <div>
              <label class="block text-sm font-medium text-gray-700">
                Children
              </label>
              <input
                type="number"
                step="1"
                v-model="children"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <!-- Infants -->
            <div>
              <label class="block text-sm font-medium text-gray-700">Infants</label>
              <input
                type="number"
                step="1"
                v-model="infants"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
          </div>
        </div>

        <!-- Payment Information -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Payment Type -->
          <div>
            <label class="block text-sm font-medium text-gray-700">
              Payment Type
            </label>
            <select
              v-model="paymentType"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="pending">Pending</option>
              <option value="cash">Cash</option>
              <option value="card">Card</option>
              <option value="credit">Credit</option>
            </select>
          </div>

          <!-- Payment Date -->
          <div v-if="showPaymentDate">
            <label class="block text-sm font-medium text-gray-700">
              Payment Date
            </label>
            <input
              type="date"
              v-model="paymentDate"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>

        <!-- Stripe Payment Element -->
        <div v-if="paymentType === 'card'" class="mt-4">
          <label class="block text-sm font-medium text-gray-700">
            Card Details
          </label>
          <div
            ref="cardElementRef"
            class="mt-1 p-3 border rounded-md shadow-sm"
          ></div>
          <div
            v-if="stripePaymentError"
            class="mt-2 text-sm text-red-600"
          >
            {{ stripePaymentError }}
          </div>
          <div
            v-if="processingPayment"
            class="mt-2 text-sm text-gray-600"
          >
            Processing payment...
          </div>
          <div
            role="alert"
            v-if="error"
            class="text-red-600 text-sm mt-2"
          >
            {{ error }}
          </div>
        </div>

        <!-- Amount -->
        <div>
          <label class="block text-sm font-medium text-gray-700">Amount</label>
          <div class="mt-1 text-lg font-medium">
            ${{ amount ? amount.toFixed(2) : '0.00' }}
          </div>
        </div>

        <!-- Rate Breakdown -->
        <div
          v-if="ratesDetails && ratesDetails.length > 0"
          class="mt-4 overflow-auto"
          style="max-height: 150px;"
        >
          <h4 class="text-sm font-medium text-gray-700">Rate Breakdown:</h4>
          <div class="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 text-sm">
            <div
              v-for="rate in ratesDetails"
              :key="rate.date"
              class="border p-2 rounded-md"
            >
              <div>
                {{ DateTime.fromISO(rate.date).toFormat('dd/LL/yyyy') }}
              </div>
              <div>
                ${{ rate.rate.toFixed(2) }}
                <span
                  v-if="rate.is_special"
                  class="text-green-600"
                >
                  (Special Rate)
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            @click="close"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            :disabled="loading"
            class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            {{ loading ? 'Creating booking...' : 'Create Booking' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
