<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { api } from '../lib/api'
import { DateTime } from 'luxon'
import DashboardNav from '../components/DashboardNav.vue'
import { debounce } from 'lodash';

interface Booking {
    id: string
    room_id: string
    name: string
    email: string
    phone: string
    address: string
    adults: number
    children: number
    infants: number
    check_in_date: string
    check_out_date: string
    customer_stripe_id: string | null
    payment_date: string | null
    amount: string
    payment_type: 'cash' | 'card' | 'credit' | 'pending',
    has_note: boolean
    created_at: string
    updated_at: string
}

const bookings = ref<Record<string, Booking[]>>({})

const currentYear = ref(new Date().getFullYear())
const searchQuery = ref('')
const loading = ref(false)
const error = ref<string | null>(null)

// Function to group bookings by month-year
function groupBookingsByMonth(bookingsArray: Booking[]): Record<string, Booking[]> {
    const months: Record<string, Booking[]> = {}
    bookingsArray.forEach(booking => {
        const monthYear = DateTime.fromISO(booking.check_in_date).toFormat('LLLL yyyy')
        if (!months[monthYear]) {
            months[monthYear] = []
        }
        months[monthYear].push(booking)
    })
    return months
}

// Load bookings function
async function loadBookings() {
    loading.value = true
    try {
        const response = await api.get(`/guests/year/${currentYear.value}`)
        bookings.value = response.data || {}
    } catch (err) {
        console.error('Failed to load bookings:', err)
        bookings.value = {}
        error.value = 'Failed to load bookings'
    } finally {
        loading.value = false
    }
}

const searchBookings = async () => {
    if (searchQuery.value.length < 3) {
        // Revert back to all bookings for the current year
        await loadBookings();
        return;
    }

    loading.value = true;
    try {
        const response = await api.get('/guests/search', {
            params: { query: searchQuery.value }
        });
        const data = response.data || [];

        // Group the search results by month-year
        if (Array.isArray(data)) {
            if (data.length === 0) {
                bookings.value = {};
            } else {
                bookings.value = groupBookingsByMonth(data);
            }
        } else {
            bookings.value = data;
        }
    } catch (err) {
        console.error('Failed to search bookings:', err);
        bookings.value = {};
        error.value = 'Failed to search bookings';
    } finally {
        loading.value = false;
    }
};


const debouncedSearchBookings = debounce(searchBookings, 300);

function getPaymentStatus(booking: Booking): string {
    if (booking.payment_type === 'pending' || !booking.payment_date) {
        return 'bg-red-300 text-white'
    }
    if (booking.payment_type === 'cash') {
        return 'bg-green-100'
    }
    if (['card', 'credit'].includes(booking.payment_type)) {
        return 'bg-yellow-100'
    }
    return 'bg-orange-100'
}

function previousYear() {
    currentYear.value--
    loadBookings()
}

function nextYear() {
    currentYear.value++
    loadBookings()
}

function editBooking(_booking: Booking) {
    // Implement edit functionality
}

function deleteBooking(_booking: Booking) {
    // Implement delete functionality
}

onMounted(() => {
    loadBookings()
})
</script>
<template>
    <div class="min-h-screen bg-blue-100">
        <DashboardNav />

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div class="space-y-8">
                <!-- Header -->
                <div class="flex justify-between items-center">
                    <h1 class="text-2xl font-semibold text-gray-900">Guest Records</h1>
                    <div class="flex space-x-4">
                        <div class="relative">
                            <input
                                type="text"
                                v-model="searchQuery"
                                @input="debouncedSearchBookings"
                                placeholder="Search guests..."
                                class="w-64 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            />
                        </div>
                        <div class="flex items-center space-x-4">
                            <button @click="previousYear"
                                class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Previous Year
                            </button>
                            <span class="text-lg font-medium">{{ currentYear }}</span>
                            <button @click="nextYear"
                                class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Next Year
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Loading State -->
                <div v-if="loading" class="flex justify-center py-12">
                    <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                </div>

                <!-- Error State -->
                <div v-else-if="error" class="bg-red-50 p-4 rounded-md">
                    <p class="text-red-700">{{ error }}</p>
                </div>

                <!-- Empty State -->
                <div v-else-if="Object.keys(bookings).length === 0" 
                    class="text-center py-12 bg-white shadow rounded-lg"
                >
                    <p class="text-gray-500">
                        {{ searchQuery.length >= 3 
                            ? 'No results found for your search' 
                            : `No bookings found for ${currentYear}` 
                        }}
                    </p>
                </div>
                
                <!-- Guest List -->
                <div v-else class="space-y-8">
                    <template v-for="(monthBookings, monthYear) in bookings" :key="monthYear">
                        <div class="bg-white shadow rounded-lg overflow-hidden">
                            <div class="bg-gray-50 px-4 py-2">
                                <h2 class="text-lg font-medium text-gray-900">{{ monthYear }}</h2>
                            </div>
                            <div class="divide-y divide-gray-200">
                                <div v-for="booking in monthBookings" :key="booking.id" 
                                    class="p-4"
                                    :class="getPaymentStatus(booking)"
                                >
                                    <div class="flex justify-between items-start">
                                        <div>
                                            <h3 class="text-lg font-medium">{{ booking.name }}</h3>
                                            <div class="mt-1 space-y-1 text-sm">
                                                <div class="grid gap-4 grid-flow-col auto-cols-auto">
                                                    <div>{{ booking.phone }}</div>
                                                    <div>{{ booking.email }}</div>
                                                    <div>Check-in: {{ DateTime.fromISO(booking.check_in_date).toFormat('dd LLL yyyy') }}</div>
                                                    <div>Check-out: {{ DateTime.fromISO(booking.check_out_date).toFormat('dd LLL yyyy') }}</div>
                                                    <div>Amount: ${{ parseFloat(booking.amount).toFixed(2) }}</div>
                                                    <div class="captialize">Payment: {{ booking.payment_type }}</div>
                                                    <div v-if="booking.payment_date">Paid: {{ DateTime.fromISO(booking.payment_date).toFormat('dd LLL yyyy') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex space-x-2">
                                            <button
                                                class="px-3 py-1 text-sm text-indigo-600 hover:bg-indigo-50 rounded-md"
                                                @click="editBooking(booking)"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                class="px-3 py-1 text-sm text-red-600 hover:bg-red-50 rounded-md"
                                                @click="deleteBooking(booking)"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

