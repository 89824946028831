<!-- src/components/BulkAddRoomModal.vue -->
<script setup lang="ts">
import { ref } from 'vue'
import { api } from '../lib/api'

// interface Props {
//   modelValue: boolean
// }

//const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'roomsAdded'])

const type = ref('')
const numbersInput = ref('')
const description = ref('')
const defaultRate = ref(0)
const loading = ref(false)
const error = ref('')

async function handleSubmit() {
  loading.value = true
  error.value = ''

  // Process the numbers input into an array
  const numbers = numbersInput.value
    .split(',')
    .map((num) => num.trim())
    .filter((num) => num !== '')

  if (numbers.length === 0) {
    error.value = 'Please enter at least one room number.'
    loading.value = false
    return
  }

  try {
    const payload = {
      type: type.value,
      numbers: numbers,
      description: description.value,
      default_rate: defaultRate.value,
    }

    const response = await api.post('/rooms/bulk', payload)
    emit('roomsAdded', response.data)
    emit('update:modelValue', false)
  } catch (e: any) {
    console.error('Failed to add rooms:', e)
    error.value = e.response?.data?.message || 'Failed to add rooms.'
  } finally {
    loading.value = false
  }
}
</script>


<template>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
    <div class="bg-white rounded-lg max-w-md w-full p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-medium text-gray-900">Bulk Add Rooms</h3>
        <button @click="$emit('update:modelValue', false)" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Close</span>
          <!-- Close icon -->
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <form @submit.prevent="handleSubmit" class="space-y-4">
        <div v-if="error" class="rounded-md bg-red-50 p-4">
          <div class="text-sm text-red-700">{{ error }}</div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">Type</label>
          <select v-model="type" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
            <option value="">Select a type</option>
            <option value="single">Single</option>
            <option value="double">Double</option>
            <option value="family">Family</option>
            <option value="queen single">Queen + Single</option>
            <option value="queen single single">Queen + Double Single</option>
            <option value="xlfamily">Extra Large Family</option>
          </select>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">Room Numbers</label>
          <input
            type="text"
            v-model="numbersInput"
            placeholder="e.g., 101, 102, 103"
            required
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
          <p class="text-xs text-gray-500 mt-1">Enter room numbers separated by commas.</p>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            v-model="description"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          ></textarea>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">Default Rate</label>
          <input
            type="number"
            step="0.01"
            v-model="defaultRate"
            required
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>

        <div class="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            @click="$emit('update:modelValue', false)"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            :disabled="loading"
            class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            {{ loading ? 'Adding...' : 'Add Rooms' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

